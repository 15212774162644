.login_section {
  height: 100vh;
  display: flex;
}
.app_preview_thumb {
  width: 50vw;
  background-color: #10101c;
  display: flex;
  align-items: center;
}
.preview_text_thumb {
  width: 50vw;
  padding-left: 70px;
  margin-right: 4%;
}
.preview_text {
  color: var(--app-txt-white, #fff);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 121.429% */
  letter-spacing: 0.38px;
}
.preview_description {
  color: var(--app-txt-white, #fff);

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.078px;
  margin-top: 30px;
  margin-bottom: 90px;
}
.app_login_thumb {
  background-color: #fafafe;
  width: 50vw;
  display: flex;

  align-items: center;
  padding-left: 130px;
}
.login_text_thumb {
  margin-bottom: 10px;
}
.login_text {
  color: var(--dashboard-txt-black, #19181c);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.login_description {
  color: var(--dashboard-txt-grey, #adaabe);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.login_input {
  width: 18vw;
  padding: 10px 0px 10px 20px;
  border-radius: 10px;
  background: #fff;
  color: var(--dashboard-txt-grey, #adaabe);
  border: 1px solid transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}
.input_title {
  margin-bottom: 10px;
  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.preview_icon {
  width: 50vw;
}
.iphone_icon {
  padding-right: 70px;
}

.login_form {
  margin-top: 40px;
}
.login_btn {
  margin-top: 10px;
  border-radius: 10px;
  background: var(--app-black, #10101c);

  padding: 12px 20px;
  align-items: center;
  color: var(--app-txt-light, #dad3cc);
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.078px;
}
.login_btn:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.preview_buttons {
  display: flex;
  flex-wrap: nowrap;
}
.app_store {
  margin-right: 10px;
}
