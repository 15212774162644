.dealers_section {
  background: var(--dashboard-bg, #fafafe);
  padding: 0px 70px;
  padding-bottom: 10px;
}
.section_text {
  color: var(--dashboard-txt-black, #19181c);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.text_thumb {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 45px;
}
.dealers_amount {
  margin-left: 10px;
  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.078px;
}
.add_dealer_btn {
  border: 1px transparent;
  border-radius: 10px;
  background: var(--app-black, #10101c);
  color: #dad3cc;
  outline: none;
  cursor: pointer;
  padding: 15px 20px;
}
.add_dealer_btn:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.cross_icon {
  vertical-align: text-bottom;
  margin-right: 5px;
}
.dealers_thumb {
  border-radius: 20px;
  background: var(--app-white, #fff);
  max-height: 595px;
  min-height: 595px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.dealers_thumb_titles {
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 20px;
}
.checkbox {
  margin-left: 30px;
  margin-right: 20px;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 2px solid var(--app-stroke-light, #b5a89c);
}
.item_checkbox:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); 
  cursor: pointer; 
}
.item_checkbox {
  margin-left: 30px;
  margin-right: 20px;
  width: 15px;
  height: 15px;
}
.dealers_company_title {
  color: var(--dashboard-txt-grey, #adaabe);
  width: 145px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.dealers_companies_thumb {
  display: flex;
  align-items: center;
}
.az_filter_icon {
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 70px;
}
.dealers_action {
  padding-right: 158px;
  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.dealers_thumb_border {
  width: 100%;
  height: 1px;
  background: rgba(242, 242, 242, 0.8);
}
.dealers_list {
  padding-bottom: 10px;
}

.dealers_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 22px;
}
.dealers_company_name {
  color: var(--dashboard-txt-black, #19181c);
  word-wrap: break-word;
  overflow-wrap: anywhere;
  width: 220px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.dealer_info_thumb {
  display: flex;
  align-items: center;
  width: 255px;
}
.dealer_description_thumb {
  display: flex;
  align-items: center;
}
.dealers_person {
  width: 180px;
  color: var(--dashboard-txt-black, #19181c);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.dealers_number {
  color: #9c8d7f;
  width: 150px;
  padding-left: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.dealers_status {
  width: 240px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.dealers_status_btn {
  color: #9c8d7f;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 50px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.dealers_status_btn_active {
  color: #4bbb49;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 50px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.plateIcon {
  border-radius: 5px;
  vertical-align: middle;
  margin-right: 10px;
  pointer-events: none;
}
.dealers_admin_actions_thumb {
  width: 160px;
  display: flex;
  padding-right: 60px;
  cursor: pointer;
}
.edit_icon {
  margin-left: 20px;
}
.edit_icon:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
  cursor: pointer; 
}
.delete_icon {
  margin-left: 20px;
}
.delete_icon:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
  cursor: pointer; 
}
.plate_link_thumb {
  width: 220px;
}
.plate_link {
  display: flex;
  border-radius: 50px;
  background: var(--dashboard-20, rgba(218, 211, 204, 0.2));
  width: 106px;
  padding: 10px 0px;
  padding-right: 5px;
  align-items: center;
}
.plates_description {
  display: flex;
}
.plates_thumb_chosen {
  display: flex;
  padding-left: 28px;
  padding-top: 22px;
  padding-bottom: 20px;
  border-radius: 20px 20px 0px 0px;
  background: var(--app-primary, #9c8d7f);
}
.plates_chosen_text {
  margin-right: 20px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.cancel_icon {
  margin-right: 17px;
  vertical-align: middle;
}
.white_border {
  width: 1px;
  height: 20px;
  background-color: #fff;
}
.plate_text_icons_thumb {
  display: flex;
}
.delete_icon {
  vertical-align: middle;
  margin-right: 5px;
}
.activate_icon {
  vertical-align: middle;

  margin-right: 5px;
}
.notification_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 420px;
  height: 212px;
}

.notification_message {
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;

  margin-bottom: 20px;
}
.notification_buttons_thumb {
  display: flex;
}
.notification_button_confirm {
  display: block;
  margin: 0 auto;
  background-color: #000;
  color: #dad3cc;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
}
.notification_button_cancel {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  color: #9c8d7f;
  border: 1px solid #9c8d7f;
  padding: 14px 20px;
  border-radius: 10px;
}
.notification_input {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--dashboard-stroke, rgba(242, 242, 242, 0.8));
  margin-bottom: 20px;
}
.notification_wrong_password_text {
  color: red;
  margin-bottom: 10px;
}
.notification_wrong_password_hidden {
  visibility: hidden;
  margin-bottom: 10px;
}

.state_filter {
  position: relative;
  width: 145px;
  display: flex;
  gap: 7px;
}

.dealers_company_title_text {
  color: var(--dashboard-txt-grey, #adaabe);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.arrow_icon {
  cursor: pointer;
  rotate: 90deg;
  width: 6px;
  padding-left: 4px;
}


.state_dropdown {
  position: absolute;
  top: 25px;
  background: #fafafe;
  border: 1px solid #fafafe;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  width: 120px;
}

.state_item {
  padding: 8px;
  cursor: pointer;
}

.state_item:hover {
  background-color: #f0f0f0;
}