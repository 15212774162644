@font-face {
  font-family: 'SF-Pro';
  src: url(../fonts/SF-Pro-Text-Medium.otf) format('otf');
}
.navigation {
  padding-top: 20px;
  padding-left: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo_icon {
  padding-top: 5px;
}
.nav_link {
  color: #000;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  padding: 9px 10px;
  border: none;
  border-radius: 30px;
}
.nav_link.active {
  background-color: rgba(218, 211, 204, 0.20);
}

.nav_link:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Тінь при ховері */
  cursor: pointer; /* Змінює курсор на "вказівник" */
}
.nav_link_active:visited {
  width: 100px;
  background: #dad3cc33;
}
.nav_link:not(:last-child) {
  margin-right: 30px;
}
.log_out_thumb {
  padding-right: 60px;
  display: flex;
  padding-bottom: 20px;
}
.user_status_thumb {
  padding: 6px;
  border-radius: 30px;
  background: rgba(218, 211, 204, 0.2);
  color: var(--app-primary, #9c8d7f);
  text-align: center;

  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
}
.user_status_icon {
  vertical-align: middle;
  margin-left: 3px;
}
.user_email {
  color: var(--dashboard-txt-black, #19181c);
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.grey_border {
  width: 1px;
  height: 40px;
  background: #dad3cc;
  margin-left: 30px;
  margin-right: 30px;
}
