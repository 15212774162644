.container {
  margin: 0 auto;
  width: 100%;

  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}
/* @media screen and (max-width: 787px) {
  .container {
    width: 100%;
    max-width: 480px;
  }
} */
ul, li, p {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Button style */
