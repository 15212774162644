.users_section {
  background: var(--dashboard-bg, #fafafe);
  padding: 0 70px;
  padding-bottom: 10px;
}
.section_text {
  color: var(--dashboard-txt-black, #19181c);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.text_thumb {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 45px;
}
.dealers_amount {
  margin-left: 10px;
  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.078px;
}
.add_dealer_btn {
  border: 1px transparent;
  border-radius: 10px;
  background: var(--app-black, #10101c);
  color: #dad3cc;
  outline: none;

  padding: 15px 20px;
}
.cross_icon {
  vertical-align: text-bottom;
  margin-right: 5px;
}
.dealers_thumb {
  border-radius: 20px;
  background: var(--app-white, #fff);
  max-height: 595px;
  min-height: 595px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.dealers_thumb_titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 20px;
}

.checkbox {
  margin-left: 30px;
  margin-right: 20px;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 2px solid var(--app-stroke-light, #b5a89c);
}
.dealers_company_title {
  /* margin-left: 30px; */

  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.dealers_company_title_name {
  margin-left: 30px;

  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.dealers_companies_thumb {
  display: flex;
  align-items: center;
}
.az_filter_icon {
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 70px;
}
.user_address {
  width: 200px;

  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.dealers_action {
  padding-right: 60px;
  color: var(--dashboard-txt-grey, #adaabe);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.dealers_thumb_border {
  width: 100%;
  height: 1px;
  background: rgba(242, 242, 242, 0.8);
}
.dealers_thumb_border_top {
  width: 100%;
  height: 1px;
  background: rgba(242, 242, 242, 0.8);
  margin-bottom: 16px;
}
.dealers_list {
  padding-bottom: 10px;
  /* margin-left: 30px;
  margin-right: 30px; */
}
.dealers_list_thumb {
  display: flex;
  flex-direction: column;
}
.users_list_thumb {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.dealers_list_item {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  padding-top: 20px;
  /* padding-bottom: 20px; */
}
.dealers_company_name {
  color: var(--dashboard-txt-black, #19181c);
  word-wrap: break-word;
  overflow-wrap: anywhere;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.dealers_company_name {
  color: var(--dashboard-txt-black, #19181c);
  word-wrap: break-word;
  overflow-wrap: anywhere;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.dealer_info_thumb {
  display: flex;
  align-items: center;
  width: 240px;
}
.dealer_description_thumb {
  display: flex;
  align-items: center;
}
.dealers_person {
  width: 260px;
  color: var(--dashboard-txt-black, #19181c);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.dealers_number {
  color: var(--dashboard-txt-black, #19181c);
  width: 220px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.dealers_email {
  width: 380px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  color: var(--dashboard-txt-black, #19181c);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.dealerIcon {
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 20px;
}
.dealers_admin_actions_thumb {
  display: flex;
  padding-right: 60px;
}
.edit_icon {
  margin-left: 20px;
}
.delete_icon {
  margin-left: 20px;
}
.actions_thumb {
  width: 60px;
}
.dropdown_arrow {
  width: 15px;
  height: 8px;
}
.user_details_menu_thumb {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.user_details_list {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.user_details_list:last-child {
  margin-bottom: 20px;
}
.user_details_item {
  padding-left: 30px;
  margin-right: 120px;
}
.user_details_item_name {
  margin-bottom: 10px;
  color: #adaabe;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.user_details {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
  width: 140px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  /* text-align: left; */
}
.details_list {
  display: flex;
}
